import React from "react";
import "./Greeting.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { illustration, greeting } from "../../portfolio";
import { Fade } from "react-reveal";
import FeelingProud from "./FeelingProud";
import emoji from "react-easy-emoji";
import landingPerson from "./landingPerson";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";

export default function Greeting(props) {
  const theme = props.theme;
  return (
    <Fade bottom duration={2000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1 className="greeting-text" style={{ color: theme.text }}>
                {greeting.title }
                <span className="wave-emoji">{emoji("👋")}</span>
              </h1>
              {greeting.nickname && (
                <h2 className="greeting-nickname" style={{ color: theme.text }}>
                  ( {greeting.nickname} )
                </h2>
              )}
              <p
                className="greeting-text-p subTitle"
                style={{ color: theme.secondaryText }}
              >
                {greeting.subTitle}
              </p>
              <SocialMedia theme={theme} />
              <div className="button-greeting-div">
                <Button text="Contact me" href="/contact" />
                {greeting.resumeLink && (
                  <Button
                    text="Curriculum Vitae"
                    newTab={true}
                    href={greeting.resumeLink}
                  />
                )}
              </div>
              {/*<div className="button-greeting-div">
              <Button text="Contact me" href="#contact" />
            </div>*/}
            </div>
          </div>
          <div className="greeting-image-div">
            {illustration.animated ? (
              <DisplayLottie animationData={landingPerson} />
              ) : (
              <img
                alt="man sitting on table"
                src={require("./manOnTable.svg")}
              ></img>
            )}
          </div>
        </div>
      </div>
    </Fade>
  );
}
